<template>
  <div :class="containerClass" @click="onDocumentClick">
    <div class="layout-wrapper-content">
      <AppTopBar :topbarMenuActive="topbarMenuActive" :profileMode="profileMode"
        :horizontal="layoutMode === 'horizontal'" :activeTopbarItem="activeTopbarItem"
        @menubutton-click="onMenuButtonClick" @topbar-menubutton-click="onTopbarMenuButtonClick"
        @topbar-item-click="onTopbarItemClick" />

      <transition name="layout-menu-container">
        <div :class="menuClass" @click="onMenuClick" v-show="isMenuVisible()">

          <div class="menu-scroll-content">
            <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive"
              :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick"
              @root-menuitem-click="onRootMenuItemClick" />
          </div>

        </div>
      </transition>

      <div class="layout-main">
        <div class="layout-breadcrumb">
          <Breadcrumb v-if="breadCrumbItems" :home="home" :model="breadCrumbItems" />
        </div>
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in" appear>
            <component :is="Component" />
          </transition>
        </router-view>
      </div>

      <div class="layout-mask" />

      <!-- <AppFooter /> -->
    </div>
  </div>
</template>

<script>
import AppTopBar from "./components/AppTopbar.vue";
import AppMenu from "./components/AppMenu.vue";
import AppFooter from "./components/AppFooter.vue";

import EventBus from "@/event-bus";

import { MainRoutesEnum } from "@/router/MainRoutesEnum";

import { UsersRoutesEnum } from "@/modules/users/router";
import { authStore } from "@/modules/auth/store";
import { ZonesRoutesEnum } from "@/modules/zones/router";
import { ShiftsRoutesEnum } from "@/modules/shifts/router";
import { ContractsRoutesEnum } from "@/modules/contracts/router";
import { MaterialsRoutesEnum } from "@/modules/materials/router";
import { TicketsRoutesEnum } from "@/modules/tickets/router";
import { LiveViewRoutesEnum } from "@/modules/live-view/router";
import { TextRoutesEnum } from "@/modules/text/router";
import { CustomShiftsRoutesEnum } from "../../modules/custom-shifts/router";
import { CompaniesRoutesEnum } from "@/modules/companies/router";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import {InvoiceMovementsRoutesEnum} from "@/modules/invoice_movements/router";
import {InvoicesRoutesEnum} from "@/modules/invoices/router";
import {ProjectsRoutesEnum} from "@/modules/projects/router";
import {NotificationsRoutesEnum} from "@/modules/notifications/router";
import {ActivitiesRoutesEnum} from "@/modules/activities/router";
import {AccessesBySection} from "../../model/enums/UserRoleEnum";

export default {
  components: {
    AppTopBar,
    AppMenu,
    AppFooter,
  },
  data() {
    return {
      home: { icon: "pi pi-home", to: "/" },

      layout: "littleleaf",
      theme: "lightgreen",
      layoutMode: "static" /* horizontal | slim | static | overlay*/,
      profileMode: "top" /* top | inline | horizontal */,
      topbarMenuActive: false,
      activeTopbarItem: null,
      rotateMenuButton: false,
      profileExpanded: false,
      darkMenu: true /* true | false */,
      menuActive: false,
      overlayMenuActive: false,
      staticMenuDesktopInactive: false,
      staticMenuMobileActive: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      //this.$toast.removeAllGroups();
    },
  },
  methods: {
    onDocumentClick($event) {
      if (!this.topbarItemClick) {
        if(this.activeTopbarItem === 'notifications'){
          const el = document.getElementById(`topbar-notifications-menu`);
          let path = $event.path || $event.composedPath()
          if(path.includes(el)) return;
        }

        this.activeTopbarItem = null;
        this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
        if (this.isHorizontal() || this.isSlim()) {
          this.menuActive = false;
          EventBus.emit("reset-active-index");
        }

        this.hideOverlayMenu();
      }


      this.topbarItemClick = false;
      this.menuClick = false;
    },
    onProfileClick(event) {
      this.profileExpanded = !this.profileExpanded;
      if (this.isHorizontal() || this.isSlim()) {
        EventBus.emit("reset-active-index");
      }

      event.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (!event.item.items) {
        EventBus.emit("reset-active-index");
        this.hideOverlayMenu();
      }
      if (!event.item.items && this.isHorizontal()) {
        this.menuActive = false;
      }
    },
    onRootMenuItemClick() {
      this.menuActive = !this.menuActive;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;

      this.rotateMenuButton = !this.rotateMenuButton;
      this.topbarMenuActive = false;

      if (this.layoutMode === "overlay") {
        this.overlayMenuActive = !this.overlayMenuActive;
      } else {
        if (this.isDesktop())
          this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
        else this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }

      event.preventDefault();
    },
    onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;
      this.hideOverlayMenu();
      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item) {
        this.activeTopbarItem = null;
      }
      else this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    hideOverlayMenu() {
      this.rotateMenuButton = false;
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isHorizontal() {
      return this.layoutMode === "horizontal";
    },
    isSlim() {
      return this.layoutMode === "slim";
    },
    isMenuVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static")
          return !this.staticMenuDesktopInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        if (this.staticMenuMobileActive) return true;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      }
    },
    replaceLink(linkElement, href) {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    },
  },
  computed: {
    breadCrumbItems() {
      return this.$route?.meta?.breadCrumbItems;
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "menu-layout-static": this.layoutMode !== "overlay",
          "menu-layout-overlay": this.layoutMode === "overlay",
          "layout-menu-overlay-active": this.overlayMenuActive,
          "menu-layout-slim": this.layoutMode === "slim",
          "menu-layout-horizontal": this.layoutMode === "horizontal",
          "layout-menu-static-inactive": this.staticMenuDesktopInactive,
          "layout-menu-static-active": this.staticMenuMobileActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    menuClass() {
      return ["layout-menu-container", { "layout-menu-dark": this.darkMenu }];
    },
    currentRole() {
      return authStore.getters.userRole;
    },
    menu() {
      const items = [
        {
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: { name: MainRoutesEnum.HOME },
          roles: AccessesBySection.DASHBOARD
        },
        {
          label: "Admin",
          icon: "fas fa-users",
          to: { name: UsersRoutesEnum.ADMINS_LIST },
          roles: AccessesBySection.ADMIN
        },
        {
          label: "Drivers",
          icon: "fas fa-users",
          to: { name: UsersRoutesEnum.USERS_LIST },
          roles: AccessesBySection.DRIVER
        },
        {
          separator: true
        },
        {
          label: "Brokers",
          icon: "pi pi-fw pi-ticket",
          to: { name: CompaniesRoutesEnum.BROKERS_LIST },
          roles: AccessesBySection.BROKERS
        },
        {
          label: "Customers",
          icon: "pi pi-fw pi-tags",
          to: { name: CompaniesRoutesEnum.CUSTOMERS_LIST },
          roles: AccessesBySection.CUSTOMERS
        },
        {
          label: "Clients",
          icon: "pi pi-fw pi-tags",
          to: { name: CompaniesRoutesEnum.CLIENTS_LIST },
          roles: AccessesBySection.CLIENTS
        },
        {
          separator: true
        },

        {
          label: "Smart Zones",
          icon: "fas fa-map",
          to: { name: ZonesRoutesEnum.SMART_ZONES },
          roles: AccessesBySection.SMART_ZONES
        },
        {
          separator: true
        },
        {
          label: "Project",
          icon: "fas fa-project-diagram",
          to: { name: ProjectsRoutesEnum.PROJECT_LIST },
          roles: AccessesBySection.PROJECTS
        },
        {
          label: "Activity",
          icon: "fas fa-sitemap",
          to: { name: ActivitiesRoutesEnum.ACTIVITY_LIST },
          roles: AccessesBySection.ACVIVITIES
        },
        {
          separator: true
        },
        {
          label: "Contracts",
          icon: "fas fa-file-signature",
          to: { name: ContractsRoutesEnum.CONTRACTS_LIST },
          roles: AccessesBySection.CONTRACTS
        },
        {
          label: "Materials",
          icon: "fas fa-cubes",
          to: { name: MaterialsRoutesEnum.MATERIALS_LIST },
          roles: AccessesBySection.MATERIALS
        },
        {
          separator: true
        },
        {
          label: "App Work Requests",
          icon: "fas fa-road",
          to: { name: CustomShiftsRoutesEnum.SHIFTS_LIST },
          roles: AccessesBySection.APP_WRS
        },
        {
          label: "Work Requests",
          icon: "fas fa-road",
          to: { name: ShiftsRoutesEnum.SHIFTS_LIST },
          roles: AccessesBySection.WRS
        },
        {
          label: "Tickets",
          icon: "fas fa-clipboard-list",
          to: { name: TicketsRoutesEnum.TICKETS_LIST },
          roles: AccessesBySection.TICKETS
        },
        {
          label: "WRS drivers",
          icon: "fas fa-road",
          to: { name: UsersRoutesEnum.WRS_LIST },
          roles: [UserRoleEnum.BROKER, UserRoleEnum.CUSTOMER, UserRoleEnum.SUPER_ADMIN, UserRoleEnum.CLIENT], // poi rimosso se customer non ha broker_id
          key: AccessesBySection.WRS_DRIVERS
        },        
        {
          separator: true
        },
        {
          label: "Ledgers movements",
          icon: "pi pi-fw pi-money-bill",
          to: { name: InvoiceMovementsRoutesEnum.INVOICE_MOVEMENTS_LIST },
          roles: AccessesBySection.LEDGER_MOVEMENTS
        },
        {
          label: "Ledgers",
          icon: "pi pi-fw pi-money-bill",
          to: { name: InvoicesRoutesEnum.INVOICES_LIST },
          roles: AccessesBySection.LEDGERS
        },
        {
          separator: true
        },
        {
          label: "Notification center",
          icon: "pi pi-fw pi-bell",
          to: { name: NotificationsRoutesEnum.NOTIFICATIONS_LIST },
          roles: AccessesBySection.NOTIFICATION_CENTER
        },
        {
          separator: true
        },
        {
          label: "Live view",
          icon: "fas fa-stream",
          to: {name: LiveViewRoutesEnum.PREFIX},
          roles: AccessesBySection.LIVE_VIEW
        },
        {
          label: "Send Text",
          icon: "fas fa-sms",
          to: { name: TextRoutesEnum.SEND_TEXT },
          roles: AccessesBySection.SEND_TEXT
        },
        {
          label: "Text List",
          icon: "fas fa-sms",
          to: { name: TextRoutesEnum.SENT_TEXTS },
          roles: AccessesBySection.TEXT_LIST
        },
      ];

      const userMe = authStore.getters.me

      function menuItemsForRole(role) {
        const clearItems = []

        let lastItem = null;
        items.filter(item => !item.roles?.length || item.roles.includes(role)).forEach(item => {
          if (!(lastItem?.separator && item.separator)) {
            lastItem = item
            clearItems.push(item)
          }
        })

        if (userMe.role == UserRoleEnum.CUSTOMER && !userMe.broker_id) {          
          const atIdx = clearItems.findIndex(x => x.key == 'wrs-drivers');                    
          if (atIdx >= 0) {
            clearItems.splice(atIdx, 1)
          }
        }

        return clearItems;
      }

      return [
        {
          label: "MENU",
          items: [
            ...menuItemsForRole(authStore.getters.userRole)
          ],
        },

      ];
    }
  },
};

  //#region MENU DEMO
  // {
  //   label: "Favorites",
  //   icon: "pi pi-fw pi-home",
  //   items: [
  //     {
  //       label: "Dashboards",
  //       icon: "pi pi-fw pi-home",
  //       items: [
  //         {
  //           label: "Generic",
  //           icon: "pi pi-fw pi-home",
  //           to: "/dashboard/generic",
  //         },
  //         {
  //           label: "Banking",
  //           icon: "pi pi-fw pi-money-bill",
  //           to: "/dashboard/dashboard_banking",
  //         },
  //       ],
  //     },
  //     {
  //       label: "Utenti",
  //       icon: "pi pi-fw pi-home",
  //       items: [
  //         {
  //           label: "Lista utenti",
  //           icon: "pi pi-fw pi-users",
  //           to: "/users",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: "UI Kit",
  //   icon: "pi pi-fw pi-star",
  //   items: [
  //     {
  //       label: "Form Layout",
  //       icon: "pi pi-fw pi-id-card",
  //       to: "/formlayout",
  //     },
  //     { label: "Input", icon: "pi pi-fw pi-check-square", to: "/input" },
  //     {
  //       label: "Float Label",
  //       icon: "pi pi-fw pi-bookmark",
  //       to: "/floatlabel",
  //     },
  //     {
  //       label: "Invalid State",
  //       icon: "pi pi-fw pi-exclamation-circle",
  //       to: "/invalidstate",
  //     },
  //     {
  //       label: "Button",
  //       icon: "pi pi-fw pi-mobile",
  //       to: "/button",
  //       class: "rotated-icon",
  //     },
  //     { label: "Table", icon: "pi pi-fw pi-table", to: "/table" },
  //     { label: "List", icon: "pi pi-fw pi-list", to: "/list" },
  //     { label: "Tree", icon: "pi pi-fw pi-share-alt", to: "/tree" },
  //     { label: "Panel", icon: "pi pi-fw pi-tablet", to: "/panel" },
  //     { label: "Overlay", icon: "pi pi-fw pi-clone", to: "/overlay" },
  //     { label: "Media", icon: "pi pi-fw pi-image", to: "/media" },
  //     { label: "Menu", icon: "pi pi-fw pi-bars", to: "/menus" },
  //     { label: "Message", icon: "pi pi-fw pi-comment", to: "/messages" },
  //     { label: "File", icon: "pi pi-fw pi-file", to: "/file" },
  //     { label: "Chart", icon: "pi pi-fw pi-chart-bar", to: "/chart" },
  //     { label: "Misc", icon: "pi pi-fw pi-circle-off", to: "/misc" },
  //   ],
  // },
  // {
  //   label: "Utilities",
  //   icon: "pi pi-fw pi-compass",
  //   items: [
  //     { label: "Display", icon: "pi pi-fw pi-desktop", to: "/display" },
  //     {
  //       label: "Elevation",
  //       icon: "pi pi-fw pi-external-link",
  //       to: "/elevation",
  //     },
  //     {
  //       label: "Flexbox",
  //       icon: "pi pi-fw pi-directions",
  //       to: "/flexbox",
  //     },
  //     { label: "Icons", icon: "pi pi-fw pi-search", to: "/icons" },
  //     { label: "Widgets", icon: "pi pi-fw pi-star-o", to: "/widgets" },
  //     { label: "Grid System", icon: "pi pi-fw pi-th-large", to: "/grid" },
  //     {
  //       label: "Spacing",
  //       icon: "pi pi-fw pi-arrow-right",
  //       to: "/spacing",
  //     },
  //     {
  //       label: "Typography",
  //       icon: "pi pi-fw pi-align-center",
  //       to: "/typography",
  //     },
  //     { label: "Text", icon: "pi pi-fw pi-pencil", to: "/text" },
  //   ],
  // },
  // {
  //   label: "Pages",
  //   icon: "pi pi-fw pi-briefcase",
  //   items: [
  //     { label: "Crud", icon: "pi pi-fw pi-pencil", to: "/crud" },
  //     {
  //       label: "Calendar",
  //       icon: "pi pi-fw pi-calendar-plus",
  //       to: "/calendar",
  //     },
  //     {
  //       label: "Timeline",
  //       icon: "pi pi-fw pi-calendar",
  //       to: "/timeline",
  //     },
  //     {
  //       label: "Landing",
  //       icon: "pi pi-fw pi-globe",
  //       url: "assets/pages/landing.html",
  //       target: "_blank",
  //     },
  //     { label: "Login", icon: "pi pi-fw pi-sign-in", to: "/login" },
  //     { label: "Invoice", icon: "pi pi-fw pi-dollar", to: "/invoice" },
  //     { label: "Wizard", icon: "pi pi-fw pi-star", to: "/wizard" },
  //     { label: "Help", icon: "pi pi-fw pi-question-circle", to: "/help" },
  //     { label: "Error", icon: "pi pi-fw pi-times-circle", to: "/error" },
  //     {
  //       label: "Not Found",
  //       icon: "pi pi-fw pi-exclamation-circle",
  //       to: "/notfound",
  //     },
  //     { label: "Access Denied", icon: "pi pi-fw pi-lock", to: "/access" },
  //     { label: "Empty", icon: "pi pi-fw pi-circle-off", to: "/empty" },
  //   ],
  // },
  // {
  //   label: "Hierarchy",
  //   icon: "pi pi-fw pi-align-left",
  //   items: [
  //     {
  //       label: "Submenu 1",
  //       icon: "pi pi-fw pi-align-left",
  //       items: [
  //         {
  //           label: "Submenu 1.1",
  //           icon: "pi pi-fw pi-align-left",
  //           items: [
  //             { label: "Submenu 1.1.1", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 1.1.2", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 1.1.3", icon: "pi pi-fw pi-align-left" },
  //           ],
  //         },
  //         {
  //           label: "Submenu 1.2",
  //           icon: "pi pi-fw pi-align-left",
  //           items: [
  //             { label: "Submenu 1.2.1", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 1.2.2", icon: "pi pi-fw pi-align-left" },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       label: "Submenu 2",
  //       icon: "pi pi-fw pi-align-left",
  //       items: [
  //         {
  //           label: "Submenu 2.1",
  //           icon: "pi pi-fw pi-align-left",
  //           items: [
  //             { label: "Submenu 2.1.1", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 2.1.2", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 2.1.3", icon: "pi pi-fw pi-align-left" },
  //           ],
  //         },
  //         {
  //           label: "Submenu 2.2",
  //           icon: "pi pi-fw pi-align-left",
  //           items: [
  //             { label: "Submenu 2.2.1", icon: "pi pi-fw pi-align-left" },
  //             { label: "Submenu 2.2.2", icon: "pi pi-fw pi-align-left" },
  //           ],
  //         },
  //       ],
  //     },
  //   ],
  // },
  //#endregion
</script>

<style lang="scss">
@import "../../assets/flags/flags.css";

/* Custom toast location */
.p-toast.p-toast-topright {
  top: 114px;
}

/* Responsive Table */
.p-datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {

  .p-datatable-responsive .p-datatable-thead>tr>th,
  .p-datatable-responsive .p-datatable-tfoot>tr>td {
    display: none !important;
  }

  .p-datatable-responsive .p-datatable-tbody>tr>td {
    text-align: left;
    display: block;
    border: 0 none !important;
    width: 100% !important;
    float: left;
    clear: left;
  }

  .p-datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
    padding: 0.4em;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4em;
    font-weight: bold;
  }
}
</style>
