
import {Options, Vue} from "vue-class-component";
import {Prop, Provide} from "vue-property-decorator";

import {AuthRoutesEnum} from "@/modules/auth/router";
import {authStore} from "@/modules/auth/store";
import TopBarMessages from "./TopBarMessages.vue";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {messagesService} from "@services/messages.service";
import moment from "moment";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {NotificationTypeEnum} from "@/model/enums/NotificationTypeEnum";
import {UsersRoutesEnum} from "@/modules/users/router";
import {resetPusher} from "@/pusher";
import {configuration} from "@plugins/Config-plugin";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";

@Options({
  components: {
    TopBarMessages
  }
})
export default class extends Vue {
  @Prop() topbarMenuActive: boolean;
  @Prop() profileMode: string;
  @Prop() horizontal: boolean;
  @Prop() activeTopbarItem: string;

  @Provide({ reactive: true })
  notifications: any = {
    list: [],
    page: 1,
    notReadCount: 0,
    per_page: 10,
  }

  loadingPusher: boolean = false

  formatNotifications(data) {
    return data.map((notification) => {
      notification.fromNow = moment.utc(notification.created_at).local().fromNow();
      return notification
    })
  }

  get logo(){
    return configuration.appLogo
  }

  mounted(){
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission();
    } else {
      alert("Notifications are blocked. Please open your browser preferences or click the lock near the address bar to change your notification preferences.");
    }
  }

  async loadNotifications(more?) {
    this.$waitFor(
        async () => {
          messagesService.index({
            page: this.notifications.page,
            per_page: this.notifications.per_page,
            sortField: 'created_at',
            sortOrder: 1
          }).then((data) => {
            if (more) {
              this.notifications.list = this.notifications.list.concat(this.formatNotifications(data.data))
            } else {
              this.notifications.list = this.formatNotifications(data.data)
            }
            this.notifications.total = data.total
            this.notifications.per_page = 10
            this.notifications.page = Math.ceil(this.notifications.list.length / this.notifications.per_page)
          })
        }
    )
  }

  notifyMe(notification) {
    new Notification(notification.message).onclick = (event) => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      this.goToDetails(notification)
    }
  }

  onPusherEvent($event){
    if(
        (typeof $event !== 'string' && !($event instanceof String)) &&
        Notification.permission == "granted"
    ){
      this.notifyMe($event)
    }

    this.loadingPusher = false
    if(this.activeTopbarItem === 'notifications'){
      this.notifications.page = 1
      this.notifications.per_page = this.notifications.list.length
      this.loadNotifications()
    }
  }

  goToDetails(item) {
    if(!item.is_read){
      this.loadingPusher = true
    }
    let routeData
    if(
        item.type === NotificationTypeEnum.DRIVER_CHANGE_BROKE_REQUEST ||
        item.type === NotificationTypeEnum.DRIVER_SIGNUP
    ) {
      routeData = {
        name: UsersRoutesEnum.USER_DETAIL,
        params: {userId: item.destination_id}
      };

    } else if(item.type === NotificationTypeEnum.SHIFT_CHANGE_LOAD_DUMP ||
          item.type === NotificationTypeEnum.SHIFT_CHANGE_QTY ||
          item.type === NotificationTypeEnum.SHIFT_CANCEL_TRUCKS
      ) {
        routeData = this.$router.resolve({
          name: ShiftsRoutesEnum.SHIFTS_DETAIL,
          params: {shiftId: item.destination_id}
        });
    } else {
      routeData = {
        name: TicketsRoutesEnum.TICKETS_DETAIL,
        params: { ticketId: item.destination_id }
      };
    }
    routeData = this.$router.resolve(routeData);
    window.open(routeData.href, '_blank');
    messagesService.read(item.id)
  }

  get canLoadMore() {
    return !this.notifications.total || this.notifications.list.length < this.notifications.total
  }

  async loadMore() {
    this.notifications.page++
    this.loadNotifications(true)
  }

  openNotificationsBox($event) {
    if(!this.activeTopbarItem){
      if(!this.notifications.list.length){
        this.loadNotifications()
      }
      this.$emit('topbar-item-click', {originalEvent: $event, item: 'notifications'})
    }
  }

  onMenuButtonClick(event) {
    this.$emit("menubutton-click", event);
  }

  onTopbarMenuButtonClick(event) {
    this.$emit("topbar-menubutton-click", event);
  }

  readAll(){
    this.loadingPusher = true
    messagesService.readAll()
  }

  logOut() {
    console.debug("logout");
    this.$localStorage.clearToken();

    resetPusher();
  }

  get canSeeMessages() {
    return authStore?.getters.userRole === UserRoleEnum.CUSTOMER ||
        authStore?.getters.userRole === UserRoleEnum.SUPER_ADMIN ||
        authStore?.getters.userRole === UserRoleEnum.BROKER;
  }

  get userName() {
    return authStore?.getters.userName;
  }

  get userRole() {
    return (
        authStore?.getters.userRole &&
        this.$t(`role.${authStore?.getters.userRole}`)
    );
  }

  get loginRoute() {
    return {name: AuthRoutesEnum.SIGN_IN};
  }

  get profileRoute() {
    return {name: AuthRoutesEnum.CURRENT_USER};
  }

  get topbarItemsClass(): any {
    return [
      "topbar-items fadeInDown",
      {
        "topbar-items-visible": this.topbarMenuActive,
      },
    ];
  }

  setLocale(locale: string) {
    this.$i18n.locale = locale;
  }
}
