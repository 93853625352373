import { User } from "@/model/api/User";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import { authStore } from "@/modules/auth/store";
import { authService } from "@services/auth.service";
import { Options, Vue } from "vue-class-component";

@Options({})
export default class ProfilePage extends Vue {
  isLoading: boolean = true;

  confirmPassword: string = null;

  currentUser: User = null;

  get isFieldEnabled() {
    return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
  }
  
  get isSaveEnabled() {
    return this.isFieldEnabled || (this.currentUser?.password && this.confirmPassword);
  }

  async onSave() {
    if (this.currentUser.password != this.confirmPassword) {
      this.$errorMessage(this.$t('validation.pwd_and_confirm_match'));
      return;
    }

    try {
      await authService.updateMe(this.currentUser);
      this.currentUser.password = null;
      this.confirmPassword = null;
    } catch (e) {
      this.$errorMessage("An error occured.");
    }

    this.$successMessage(this.$t(`profile.messages.update_success`));
  }

  mounted() {
    this.currentUser = { ...authStore.getters.me };
  }
}