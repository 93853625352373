import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-11982f59")
const _hoisted_1 = {
  class: "pi pi-bell",
  style: {"font-size":"2rem"}
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_badge = _resolveDirective("badge")!

  return _withDirectives((_openBlock(), _createElementBlock("i", _hoisted_1, null, 512)), [
    [_directive_badge, _ctx.unread_count]
  ])
}