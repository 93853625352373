
import { Options, Vue }    from "vue-class-component";
import { Channel }         from "pusher-js";

import {PusherChannelEnum, PusherEventsEnum, subscribeChannel} from "@/pusher";
import {authStore} from "@/modules/auth/store";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {messagesService} from "@services/messages.service";
import {Inject} from "vue-property-decorator";

@Options( {
  name: "TopBarMessages",
  emits: ['onCountChange', 'onEvent']
} )
export default class extends Vue {
  channel: Channel = null;
  audio: any;
  private unread_count: number = null;

  @Inject()
  readonly notifications

  subscribeChannel() {
    const me = authStore.getters.me
    if(me.role === UserRoleEnum.SUPER_ADMIN){
      this.channel = subscribeChannel(PusherChannelEnum.CHAT_ADMIN)
    } else if(me.role === UserRoleEnum.CUSTOMER){
      this.channel = subscribeChannel(`${ PusherChannelEnum.CHAT_CUSTOMER }-${ me.customer_id }`)
    } else if(me.role === UserRoleEnum.BROKER){
      this.channel = subscribeChannel(`${ PusherChannelEnum.CHAT_BROKER }-${ me.broker_id }`)
    }
  }

  read(){
    this.updateCounter()
    this.$emit('onEvent', 'read');
  }
  readAll(){
    this.updateCounter()
    this.$emit('onEvent', 'readAll');
  }
  new($event){
    this.audio.play();
    this.updateCounter()
    this.$emit('onEvent', $event.data);
  }

  updateCounter(){
    messagesService.unreadCounter().then((res) => {
      if(res.unread_count != this.unread_count){
        this.$emit('onCountChange', res.unread_count);
      }
      this.unread_count = res.unread_count
    })
  }

  listenEvents() {
    this.subscribeChannel();

    this.channel.bind(
        PusherEventsEnum.READ,
        this.read
    );

    this.channel.bind(
        PusherEventsEnum.READ_ALL,
        this.readAll
    );

    this.channel.bind(
        PusherEventsEnum.NEW,
        this.new
    );
  }

  mounted() {
    this.audio = new Audio(require('@/assets/bip.wav'));
    this.listenEvents();
    this.updateCounter()
  }

}
